.detailWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.wrapperTransaction {
  display: flex;
  align-items: center;
  gap: 16px;
}

.stackedImage {
  position: relative;
  padding-left: 16px;
}

.fronImage {
  z-index: 2;
}

.backImage {
  position: absolute;
  transform: translate(-15px, 8px);
  z-index: 1;
}
